import React from 'react';
import Grid from '@mui/material/Grid2';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import DataCard from 'components/feed-management/DataCard';
import { ItemPreviewDialog } from 'components/feed-management/ItemPreview';
import InfiniteScroll from 'components/ui-components/InfiniteScroll';

/**
 * Show the grid view for the data set items.
 */
class DataSetManagerGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            detailViewOpenId: null
        };
    }

    /**
     * Open the detail view
     * @param {object} data
     */
    openDetailView = (data = {}) => {
        this.setState({ detailViewOpenId: data._id });
    };

    render() {
        const { list, onHandleEditItem, onOpenConfirmDialog, displayTypes, hasMore, fetchDataSetItems, dataSet } = this.props;
        const { detailViewOpenId } = this.state;

        const datacardMapping = dataSet && dataSet.customData && dataSet.customData.datacardMapping ? dataSet.customData.datacardMapping : null;

        return (
            <InfiniteScroll
                loadMore={() => fetchDataSetItems(list)}
                hasMore={hasMore}
                getScrollParent={() => document.getElementById('standard-page-scroll-parent')}
                initialLoad={false}
                loader={
                    <React.Fragment key={0}>
                        <div className="feed-management-data-view__empty">
                            <CircularProgress />
                        </div>
                    </React.Fragment>
                }
                useWindow={false}>
                <Grid container spacing={3}>
                    {list.map((item, index) => (
                        <React.Fragment key={index}>
                            <Grid size={3}>
                                <DataCard
                                    item={item}
                                    canEdit={dataSet.isManual}
                                    canDelete={dataSet.isManual}
                                    canOpenDetailView
                                    mapping={datacardMapping}
                                    displayTypes={displayTypes}
                                    onEdit={onHandleEditItem}
                                    onDelete={onOpenConfirmDialog}
                                    onOpenDetailView={(data) => this.openDetailView(data)}
                                />
                            </Grid>

                            {detailViewOpenId === item._id && (
                                <ItemPreviewDialog
                                    item={item}
                                    displayTypes={displayTypes}
                                    showDialog={true}
                                    onClose={() => this.setState({ detailViewOpenId: null })}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </Grid>
            </InfiniteScroll>
        );
    }
}

export default DataSetManagerGrid;
